import React, { useEffect, useRef, useState } from "react";
import "./css/Header.css";
import { FaBars, FaRegBookmark } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineNotifications, MdOutlineLocationOn } from "react-icons/md";
import { FaMoon } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { RiLoginCircleLine, RiLogoutCircleRLine } from "react-icons/ri";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import userimg from "../../Assets/userimg.png";
import userPhoto from "../../Assets/userPhoto.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/user";
import Modal from "react-bootstrap/Modal";
import logoutimg from "../../Assets/logoutimg.png";

export default function HeaderDetails() {
  const [mobilenavbar, setMobilenavbar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  const displayPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/verifyemail" ||
    location.pathname === "/doormanagement" ||
    location.pathname === "/scanner";
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logouts = () => {
    setShowModal(false);
    dispatch(logout());
    setMobilenavbar(false);
    navigate("/login");
    setIsDropdownVisible();
  };
  let inputValue = location.pathname.split("/").pop();
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {!displayPage && (
        <>
          {" "}
          <header
            id="header"
            className={
              isSticky ? "header-scrolled forsticky" : "header-scrolled"
            }
          >
            <div className="container d-flex align-items-center">
              <h1 className="logo me-auto">
                <Link to="/">
                  <img
                    src={eventgemLogo}
                    alt="Company Logo"
                    className="img-fluid"
                    fetchpriority="high"
                  />
                </Link>
              </h1>
              <nav
                id="navbar"
                className={
                  mobilenavbar === false ? "navbar" : "navbar-mobile nav-active"
                }
              >
                <ul>
                  <Link to="/">
                    <img
                      src={eventgemLogo}
                      alt="Company Logo"
                      className="img-fluid ms-3 mb-3 d-block d-lg-none nav-data"
                      onClick={() => setMobilenavbar(false)}
                      fetchpriority="high"
                    />
                  </Link>
                  <div className="icon-link d-block d-lg-none px-3">
                    {/*<li className="d-block pb-2">
                      <FaRegBookmark
                        style={{ width: "30px", height: "25px" }}
                      />{" "}
                      <span className="text-black">Favourite</span>
                    </li>
                    <li className="d-block py-2">
                      <MdOutlineNotifications
                        style={{ width: "30px", height: "30px" }}
                      />{" "}
                      <span className="text-black">Notification</span>
                    </li>
                    <li className="d-block py-2">
                      <FaMoon style={{ width: "30px", height: "25px" }} />{" "}
                      <span className="text-black">Dark Mode</span>
              </li>*/}
                    <li className="d-block py-2 position-relative">
                      {isLoggedIn === true ? (
                        <Link
                          to="/userhome/profile"
                          onClick={() => setMobilenavbar(false)}
                          className="p-0"
                        >
                          {" "}
                          <img
                            src={userPhoto}
                            alt=""
                            className="img-fluid user-nav-img "
                          />{" "}
                          <span className="text-black">{user.first_name}</span>{" "}
                        </Link>
                      ) : (
                        <>
                          <RiLoginCircleLine
                            style={{ width: "30px", height: "26px" }}
                          />{" "}
                          <Link
                            className="login-cta text-white btn btn-primary"
                            onClick={() => setMobilenavbar(false)}
                            to={`/login?inputValue=${inputValue}`}
                          >
                            Login
                          </Link>
                        </>
                      )}
                    </li>
                    {isLoggedIn === true && (
                      <li
                        className="d-block py-2"
                        onClick={() => setShowModal(true)}
                      >
                        <RiLogoutCircleRLine
                          style={{ width: "30px", height: "26px" }}
                        />{" "}
                        <span className="logout-cta">Log Out</span>
                      </li>
                    )}
                  </div>
                  {/*<li className="icon-link d-none d-lg-block">
                    <FaRegBookmark style={{ width: "24px", height: "19px" }} />
                  </li>
                  <li className="icon-link d-none d-lg-block">
                    <MdOutlineNotifications
                      style={{ width: "24px", height: "24px" }}
                    />
                  </li>
                  <li className="icon-link d-none d-lg-block">
                    <FaMoon style={{ width: "24px", height: "20px" }} />
                  </li>*/}
                  <li className="d-none d-lg-block position-relative">
                    {isLoggedIn === true ? (
                      // <div className="d-flex align-item-center">
                      //   {" "}
                      //   <Link to="/userhome/profile" className="p-0">
                      //     {" "}
                      //     <img
                      //       src={userPhoto}
                      //       alt=""
                      //       className="img-fluid"
                      //     />{" "}
                      //   </Link>
                      //   <span
                      //     className="login-cta ms-3 btn btn-primary text-white"
                      //     onClick={logouts}
                      //   >
                      //     Log Out
                      //   </span>
                      // </div>
                      <div className="img-cont">
                        <div
                          onClick={() =>
                            setIsDropdownVisible(!isDropdownVisible)
                          }
                        >
                          <img
                            src={userPhoto}
                            alt="User Icon"
                            className="img-fluid"
                          />
                        </div>
                        {isDropdownVisible && (
                          <div ref={dropdownRef} className="img-dropdown">
                            <ul>
                              <li
                                onClick={() => {
                                  navigate("/userhome/profile");
                                  setIsDropdownVisible(false);
                                }}
                              >
                                Profile
                              </li>
                              <li onClick={() => setShowModal(true)}>Logout</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        className="login-cta text-white btn btn-primary"
                        to={`/login?inputValue=${inputValue}`}
                      >
                        Login
                      </Link>
                    )}
                  </li>
                </ul>
                {mobilenavbar === false ? (
                  <FaBars
                    className="mobile-nav-toggle"
                    onClick={() => setMobilenavbar(true)}
                  />
                ) : (
                  <RxCross2
                    className="mobile-nav-toggle"
                    onClick={() => setMobilenavbar(false)}
                  />
                )}
              </nav>
            </div>
          </header>
          {/*<div className="search-box">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center listing-box">
                  <div className="d-flex align-items-center">
                    <MdOutlineLocationOn
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#024DDF",
                      }}
                    />
                    <h2 className="location">Chicago</h2>
                  </div>
                  <button className="event-list-btn">List Your Event</button>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end pe-4">
                  <div className="search-inp-box">
                    <IoMdSearch
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#024DDF",
                      }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
                    </div>*/}
        </>
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        dialogClassName="my-modal p-0"
      >
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12 logout-model my-4">
                <h3>Are you sure you want to leave?</h3>
                <p>Aren't we a match made in the event heaven??</p>
                <div className="d-flex justify-content-center">
                  <img src={logoutimg} alt="" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="stay-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Please stay
                  </button>
                  <button className="later-btn" onClick={logouts}>
                    See you later
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
