import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./Render.css";
import { updateLastPage } from "../../redux/user";
export default function BackToTop() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else if (window.scrollY <= 300) {
        setVisible(false);
      }
    });
  }, []);
  return (
    <>
      {pathname == "/" ||
      pathname == "/login" ||
      pathname == "/paymentSuccess" ||
      pathname == "/checkout?payment=cancel" ||
      pathname == "/scanner" ||
      pathname == "/doormanagement" ? (
        ""
      ) : (
        <i
          className="bi bi-arrow-left-circle button-back"
          onClick={() => navigate(-1)}
        ></i>
      )}
      {visible ? (
        <>
          {" "}
          <i
            className="bi bi-arrow-up-circle button-up ms-3"
            onClick={scrollToTop}
          ></i>
        </>
      ) : (
        ""
      )}
    </>
  );
}
