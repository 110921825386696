import React, { useEffect, useState } from "react";
import { MdOutlineFileDownload, MdOutlineLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import mainpost from "../../Assets/mainpost.png";
import sentenceCase from "../../utils/utilities";
import { FaRegCheckCircle } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";

import pass from "../../Assets/localpass.png";
import cross from "../../Assets/Divider1.png";
import Slider from "react-slick";
import moment from "moment";
import { GoDotFill } from "react-icons/go";
import { devApi } from "../../utils/constants";
export default function OrderHistory() {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    speed: 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  const [showModal, setShowModal] = useState(false);
  const [viewMoreOrder, setViewMoreOrder] = useState();
  const [orderData, setOrderData] = useState([
    {
      image: mainpost,
      totalticket: 2,
      eventTitle: "Garba Night with Priyanka Kher",
      startDate: "2024-04-18 23:54:00",
      venue: "Pearl Banquets, Chicago",
      bookingDate: "2024-04-18 23:54:00",
      bookingId: "1234567",
      paymentMethod: "Card",
      ticketId: "10293",
    },
    {
      image: mainpost,
      totalticket: 2,
      eventTitle: "Garba Night with Priyanka Kher",
      startDate: "2024-04-18 23:54:00",
      venue: "Pearl Banquets, Chicago",
      bookingDate: "2024-04-18 23:54:00",
      bookingId: "1234567",
      paymentMethod: "Card",
      ticketId: "10293",
    },
    {
      image: mainpost,
      totalticket: 2,
      eventTitle: "Garba Night with Priyanka Kher",
      startDate: "2024-04-18 23:54:00",
      venue: "Pearl Banquets, Chicago",
      bookingDate: "2024-04-18 23:54:00",
      bookingId: "1234567",
      paymentMethod: "Card",
      ticketId: "10293",
    },
  ]);
  const [orderDetails, setOrderDetails] = useState();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const token = useSelector((state) => state.user.token);
  useEffect(() => {
    const getOrderHistory = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
        },
      };
      axios
        .post(`${devApi}/api/order/showOrdersApi`, null, config)
        .then((response) => {
          setOrderDetails(response.data.orders);
        });
    };
    getOrderHistory();
  }, []);
  const handleViewMore = (orderObject) => {
    setViewMoreOrder(orderObject);
    setShowModal(true);
  };
  const handleCloseViewMore = () => {
    setViewMoreOrder();
    setShowModal(false);
  };
  const handleDownloadTicket = (order) => {
    // using Java Script method to get PDF file
    const headers = {
      Authorization: "Bearer " + token,
      "Content-type": "application/json",
    };
    if (order) {
      fetch(order.ticketfullpath, {
        method: "GET",
        headers: headers,
        //body: JSON.stringify(body),
      }).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${order.event.title}_${order.first_name}_${order.order_reference}`;
          alink.click();
        });
      });
    }
  };
  if (!orderDetails) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container">
        {orderDetails.length ? (
          <div className="row d-flex align-items-center justify-content-evenly">
            {orderDetails.map((order, index) => (
              <div className="col-lg-4 col-md-12 col-sm-12 col-12" key={index}>
                <div className="payment-box mb-4">
                  <div className="event-order-box1 mb-4">
                    <div className="d-flex justify-content-between w-100 mb-2">
                      <h5 className="confirmed-h5">
                        {" "}
                        <FaRegCheckCircle size={20} color="#1EA133" /> Confirmed
                      </h5>
                      <div className="d-flex justify-content-center flex-column align-items-center total-count">
                        <h5>{order.amountdetails.total_ticket_quantity}</h5>
                        <h6>Tickets</h6>
                      </div>
                    </div>
                    <div className="under-ticket d-flex justify-content-between">
                      <img
                        src={
                          order.event_ext.event_image_fullpath
                            ? order.event_ext.event_image_fullpath
                            : ""
                        }
                        alt=""
                        className="payment-img"
                      />
                      <div className="under-ticket1 ms-4">
                        <h6>{order.event.title}</h6>
                        <p>{order.event.start_date} </p>
                        <p>
                          <MdOutlineLocationOn
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#024DDF",
                            }}
                          />{" "}
                          {order.event.venue_name}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <h6
                        className="ticket-more-btn"
                        onClick={() => handleViewMore(order)}
                      >
                        View More
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="order-data">
                      <h5>Booking Date & Time:</h5>
                      <h6>{order.created_at}</h6>
                    </div>
                    <div className="order-data">
                      <h5>Booking ID:</h5>
                      <h6>{order.order_reference}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="d-flex justify-content-center text-center align-items-center no-ticket">
                <h4 className="text-center">You have not booked any ticket!</h4>
              </div>
            </div>
          </div>
        )}
      </div>

      {viewMoreOrder ? (
        <Modal
          show={showModal}
          onHide={() => handleCloseViewMore()}
          centered
          size="md"
          dialogClassName="my-modal"
        >
          <Modal.Body>
            <div
              className="event-order-box pb-0"
              style={{ backgroundColor: "unset" }}
            >
              <div className="d-flex justify-content-between w-100 mb-2">
                <h5 className="confirmed-h5">
                  {" "}
                  <FaRegCheckCircle size={20} color="#1EA133" /> Confirmed
                </h5>
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <h5>{viewMoreOrder.amountdetails.total_ticket_quantity}</h5>
                  <h6>Tickets</h6>
                </div>
              </div>
              <div className="under-ticket d-flex justify-content-between">
                <img
                  src={
                    viewMoreOrder.event_ext.event_image_fullpath
                      ? viewMoreOrder.event_ext.event_image_fullpath
                      : mainpost
                  }
                  alt=""
                  className="payment-img"
                />
                <div className="under-ticket1">
                  <h6>{sentenceCase(viewMoreOrder.event.title)}</h6>
                  <p>
                    {moment(viewMoreOrder.event.start_date).format(
                      "ddd DD, MMMM, YYYY"
                    )}{" "}
                    <GoDotFill
                      style={{ width: "8px", height: "8px", color: "#121212" }}
                    />{" "}
                    {moment(viewMoreOrder.event.start_date).format("hh:mm A")}
                  </p>

                  <p>
                    <MdOutlineLocationOn
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#024DDF",
                      }}
                    />{" "}
                    {viewMoreOrder.event.venue_name}
                  </p>
                </div>
              </div>
              <div className="qrcode-box justify-content-center  align-items-center my-2">
                <div className="col-lg-12 col-sm-4 col-md-6 slide-main-box">
                  <Slider {...settings}>
                    {viewMoreOrder.ticketsQRCode.map((image, index) => {
                      return (
                        <div className="">
                          <div className="d-flex flex-column justify-content-center align-items-center qr-show mt-2">
                            <img
                              id={index}
                              src={image}
                              alt=""
                              className="img-fluid my-2"
                              style={{ borderRadius: "20px" }}
                            />
                            <span className="text-secondary my-2">
                              ({index + 1}/{viewMoreOrder.ticketsQRCode.length})
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
              <div className="d-flex justify-content-evenly mt-4">
                {viewMoreOrder.order_items?.map((ticketType, index) => (
                  <>
                    <div className="d-flex passes-data align-items-center">
                      <img src={pass} alt="" className="img-fluid" />
                      <h6>
                        {ticketType.title} x {ticketType.quantity}
                      </h6>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", height: "35px" }}>
              <img
                src={cross}
                alt=""
                style={{ width: "-webkit-fill-available" }}
              />
            </div>
            <div
              className="event-order-box"
              style={{ backgroundColor: "unset" }}
            >
              {viewMoreOrder.order_items?.map((ticketType, index) => (
                <>
                  <div className="d-flex justify-content-between align-items-center ticketcharge ">
                    <p>
                      {ticketType.title} x {ticketType.quantity}
                    </p>
                    <p>
                      {" "}
                      $
                      {(ticketType.unit_price * ticketType.quantity).toFixed(2)}
                    </p>
                  </div>
                </>
              ))}
              <div className="d-flex justify-content-between align-items-center processcharge ">
                <p>Platform fee</p>
                <p>
                  {" "}
                  $
                  {parseFloat(viewMoreOrder.amountdetails.platform_fee).toFixed(
                    2
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center processcharge">
                <p>Processing fee</p>
                <p>
                  {" "}
                  $
                  {parseFloat(
                    viewMoreOrder.amountdetails.processing_fee
                  ).toFixed(2)}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center processcharge">
                <h6>Total amount</h6>
                <h4>$ {viewMoreOrder.amountdetails.full_total.toFixed(2)}</h4>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-4">
              <a
                href={viewMoreOrder.ticketfullpath}
                target="_blank"
                rel="noreferrer"
              >
                <button className="ticket-download-btn">
                  {" "}
                  <MdOutlineFileDownload size={22} className="me-3" />
                  Download Ticket
                </button>
              </a>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}
