import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./user";
import ticketSlice from "./ticket";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage
};

const reducer = combineReducers({
    user: authSlice,
    tickets:ticketSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false 
        })
});

export default store;
