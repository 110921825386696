import React, { useEffect, useRef, useState } from "react";
import Banner from "./Banner";
import Organiser from "./Organiser";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEventDetails } from "../../redux/ticket";
import { updateEventId } from "../../redux/user";
import { devApi } from "../../utils/constants";
import axios from "axios";

export default function HomePage() {
  const [eventData, setEventData] = useState(null);
  const { inputValue } = useParams();
  const ticketSummaryRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateEventId(inputValue));
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`
        );
        setEventData(response.data);

        dispatch(setEventDetails(response.data));
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, []);
  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {eventData.data ? (
        <>
          <Banner ticketOrder={ticketSummaryRef} data={eventData} />
          <Organiser ticketOrder={ticketSummaryRef} data={eventData} />
        </>
      ) : (
        <div className="container ">
          <div
            className="row d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6">
              <h1 className="text-center">No data Found</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
