import React, { useRef } from "react";
import "./css/EventLogin.css";
import { TbBrandGoogle } from "../../Assets/google_color.js";
import { Link, useNavigate } from "react-router-dom";
import us from "../../Assets/Us.svg";
import { useState } from "react";
import { useEffect } from "react";
import forgotmodal from "../../Assets/forgotmodal.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/user";
import { toast } from "react-toastify";
import { getTotalTickets } from "../../redux/ticket";
import { signupVerify } from "../../redux/user";
import store from "../../redux/store";
import validator from "validator";
import TicketOrder from "../TicketData/TicketOrder";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import axios from "axios";
import { MdKeyboardArrowDown } from "react-icons/md";
import { devApi } from "../../utils/constants";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function EventLogin() {
  const token = useSelector((state) => state.user.token);
  //const eventDetails = useSelector((state) => state.ticket.eventDetails);
  const [cancelLoad, setCancelLoad] = useState(false);
  const [verifyOtpLoad, setVerifyOtpLoad] = useState(false);
  const [loginPage, setLoginPage] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpErrors, setOtpErrors] = useState({
    otpInvalid: "",
    otpResend: "",
  });
  const [viewForgotPassword, setViewForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState();
  const [proceedLoading, setProceedLoading] = useState(false);
  const [checkoutLoad, setCheckoutLoad] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState();
  const [otp, setOtp] = useState("");
  const [otpsent, setOtpsent] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [loadMOdal, setLoadModal] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const [checkoutSuccess, setCheckoutSuccess] = useState({
    mobileSuccess: false,
    emailSuccess: false,
    agreeSuccess: false,
  });
  const totalTickets = useSelector(getTotalTickets);
  const ticketRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState(
    useSelector((state) => state.tickets.eventDetails)
  );
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [loginErrors, setLoginErrors] = useState({ email: "", password: "" });
  //signup variables
  const [viewSignup, setViewSignup] = useState(false);
  const signupDetails = useSelector((state) => state.user.signupDetails);
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [signupErrors, setSignupErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [verifyEmailErrors, setVerifyEmailErrors] = useState({
    email: "",
    otpError: "",
  });
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typing, setTyping] = useState(false);
  const [strength, setStrength] = useState();
  const [passScore, setPassScore] = useState(0);
  const Ref = useRef(null);
  const [disable, setDisable] = useState(false);
  const [messages, setMessages] = useState({
    emailError: "",
    emailSuccess: "",
    emailCheck:
      "Do check your spam and junk email folder if you don't find email in your primary inbox",
  });
  const [data, setData] = useState({
    email: "",
  });
  const [forgotPasswordLoad, setForgotPasswordLoad] = useState(false);
  const updateData = (e) => {
    if (messages.emailError) {
      setMessages({ ...messages, emailError: null });
    }
    setData({
      ...data,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
  };
  const resetPassword = async () => {
    setForgotPasswordLoad(true);
    if (!validator.isEmail(data.email)) {
      setMessages({ ...messages, emailError: "Please enter a valid email" });
      setForgotPasswordLoad(false);
      return;
    }
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      email: data.email,
    };

    await axios
      .post(`${devApi}/api/resetPasswordLink`, body, config)
      .then((response) => {
        if (response.data.success) {
          setForgotPasswordLoad(false);
          setDisable(true);
          setMessages({ ...messages, emailSuccess: response.data.message });
        } else {
          setForgotPasswordLoad(false);
          setMessages({ ...messages, emailError: response.data.message });
        }
      })
      .catch((error) => {
        setForgotPasswordLoad(false);
        setMessages({ ...messages, emailError: error.response.data.message });

        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });

    // setOtpsent(true);
    // setNewPasswordField(true);
    // setEmailField(false);
  };
  //useEffects
  useEffect(() => {
    if (!eventData) {
      isLoggedIn ? navigate("/") : navigate("/login");
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment = urlParams.get("payment");
    const fetchUserData = async () => {
      setCancelLoad(true);
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
        },
      };

      await axios
        .get(`${devApi}/api/get_client`, config)
        .then((response) => {
          if (response.data.client) {
            setCancelLoad(false);
            setMobileError("OTP verified successfully");
            setDisableField(true);
            setCheckoutSuccess({
              ...checkoutSuccess,
              mobileSuccess: true,
              emailSuccess: true,
            });
            setSignupdata({ ...signupdata, email: response.data.client.email });
            setMobile(response.data.client.phone);
            setClientData(response.data.client);
          } else {
            setCancelLoad(false);
          }
        })
        .catch((error) => {
          setCancelLoad(false);
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    //fetchEventDetails();
    if (payment) fetchUserData();
  }, []);
  // useEffect(() => {
  //   const loadInitialState = async () => {
  //     const storedState = localStorage.getItem("ticketState");
  //     if (storedState) {
  //       const initialState = JSON.parse(storedState);
  //       store.dispatch(initializeTicketTypes(initialState.ticketTypes));
  //     } else {
  //       try {
  //         const initialTicketTypes = eventData.data.tickets.map((ticket) => ({
  //           type: ticket.description,
  //           price: parseInt(parseFloat(ticket.price)),
  //           quantity: 1,
  //         }));
  //         store.dispatch(initializeTicketTypes(initialTicketTypes));
  //         localStorage.setItem(
  //           "ticketState",
  //           JSON.stringify({ ticketTypes: initialTicketTypes })
  //         );
  //       } catch (error) {
  //         console.error("Error fetching initial state from API:", error);
  //       }
  //     }
  //   };
  //   loadInitialState();
  // }, []);
  useEffect(() => {
    if (otpsent === true && seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [otpsent, seconds]);
  useEffect(() => {
    fetch(`${devApi}/api/googleAuthLogin`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrlGoogle(data.url))
      .catch((error) => console.error(error));
  }, []);
  //functions-Handlers
  const updateSignupData = (e) => {
    let { name, value } = e.target;
    setSignupdata({
      ...signupdata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    setSignupErrors({ ...signupErrors, [e.target.name]: "" });
    // const isMatch = name === 'confirm_password' && signupdata.password === value;
    switch (name) {
      case "first_name":
        setSignupErrors({
          ...signupErrors,
          first_name: value.length === 0 ? "Name is required" : "",
        });
        break;
      case "email":
        setSignupErrors({
          ...signupErrors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setSignupErrors({
          ...signupErrors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      // case "confirm_password":setErrors({...errors,confirm_password: !isMatch ? "Password must not match the original password." : ""})
      // break;
      default:
        break;
    }
  };
  const checkSignupError = () => {
    const newErrors = { ...signupErrors };
    let dataIsValid = true;
    if (!signupdata.first_name) {
      dataIsValid = false;
      newErrors.first_name = "First Name is required";
    } else if (validator.isAlpha(signupdata.first_name)) {
      newErrors.first_name = "";
    } else {
      newErrors.first_name = "Please enter a valid first name";
    }
    if (!signupdata.last_name) {
      dataIsValid = false;
      newErrors.last_name = "Last Name is required";
    } else if (validator.isAlpha(signupdata.last_name)) {
      newErrors.last_name = "";
    } else {
      newErrors.last_name = "Please enter a valid last name";
    }
    if (!signupdata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(signupdata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (signupdata.phone) {
      if (validator.isMobilePhone(signupdata.phone)) {
        newErrors.phone = "";
      } else {
        dataIsValid = false;
        newErrors.phone = "Please enter a valid mobile number";
      }
    }
    if (!signupdata.password) {
      dataIsValid = false;
      newErrors.password = "Password is required";
    } else {
      if (signupdata.password.length >= 8) {
        newErrors.password = "";
      } else {
        dataIsValid = false;
        newErrors.password = "Password should contain minimun 8 characters";
      }
    }
    if (signupdata.password !== signupdata.confirm_password) {
      dataIsValid = false;
      newErrors.confirm_password = "Password must match the original password.";
    } else {
      newErrors.confirm_password = "";
    }
    if (!dataIsValid) {
      setSignupErrors(newErrors);
      return false;
    }
    return true;
  };
  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };
  const signup = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkSignupError()) {
          return;
        }

        const body = {
          ...signupdata,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        await axios
          .post(`${devApi}/api/registerClient`, body, headers)
          .then((res) => {
            if (res.data.success) {
              store.dispatch(signupVerify(res.data));
              setViewSignup(false);
              setVerifyEmail(true);
              setOtpsent(true);
              setSeconds(30);
              //navigate("/verifyemail");
              // toast.success(
              //   "Great! Your account is created. Please login to continue"
              // );
              // toast.success(res.data.message)
            } else {
              if (res.data.error.email[0]) {
                setSignupErrors({
                  ...signupErrors,
                  email: res.data.error.email[0],
                });
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        console.error("Signup error:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleResendEmailOtp = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      client_id: signupDetails.data.cust_account_id,
    };

    await axios
      .post(`${devApi}/api/resentOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          toast.success(`OTP sent to ${signupDetails.data.email} `);
          setOtpsent(true);
          setSeconds(30);
        } else {
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  const updateLoginData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "email":
        setLoginErrors({
          ...loginErrors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setLoginErrors({
          ...loginErrors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };

  const updateMobile = (e) => {
    if (mobileError) {
      setMobileError("");
    }
    setMobile(e.target.value);
  };
  const checkLoginError = () => {
    const newErrors = { ...loginErrors };
    let dataIsValid = true;
    if (!logindata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(logindata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (!dataIsValid) {
      setLoginErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkLoginError()) {
          return;
        }
        const res = await axios.post(`${devApi}/api/clientLogin`, logindata);
        if (res.data.success) {
          dispatch(login({ token: res.data.token, user: res.data.user }));
          // toast.success(res.data.message);
          toast.success("Logged in Successfully");
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleCheckboxChange = (e) => {
    setCheckoutSuccess({
      ...checkoutSuccess,
      agreeSuccess: e.target.checked,
    });
  };

  const verifyOtp = async () => {
    setLoadModal(true);
    setOtpsent(false);

    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      mobile_otp: otp,
    };

    await axios
      .post(`${devApi}/api/e/verifyMobileOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          response.data.data.email
            ? setSignupdata(
                {
                  ...signupdata,
                  email: response.data.data.email,
                },
                setCheckoutSuccess({
                  ...checkoutSuccess,
                  mobileSuccess: true,
                  emailSuccess: true,
                })
              )
            : setCheckoutSuccess({ ...checkoutSuccess, mobileSuccess: true });
          setDisableField(true);
          setOtpsent(false);
          setShowModal(false);
          setLoadModal(false);
          setMobileError(response.data.message);
        } else {
          setOtpsent(true);
          setSeconds(30);
          setLoadModal(false);
          setOtp();
          setOtpErrors({ ...otpErrors, otpInvalid: response.data.message });
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  const handleResendOtp = () => {
    handleMobileVerify();
  };
  const handleMobileVerify = async () => {
    if (validator.isMobilePhone(mobile)) {
      setProceedLoading(true);
      setMobileError("");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
        },
      };
      const body = {
        mobile_num: mobile,
      };

      await axios
        .post(`${devApi}/api/e/generateMobileOTP`, body, config)
        .then((response) => {
          if (response.data.success) {
            toast.success(`OTP sent to ${mobile} `);
            setOtpsent(true);
            setShowModal(true);
            setProceedLoading(false);
          } else {
          }
        })
        .catch((error) => {
          setProceedLoading(false);
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    } else {
      setMobileError("Enter valid mobile number!");
    }
  };
  const handleCheckout = () => {
    setCheckoutLoad(true);
    ticketRef.current.childMethod();
  };
  const verifyEmailOtp = async () => {
    setVerifyOtpLoad(true);

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const body = {
      email: signupDetails.data.email,
      password: signupDetails.raw_pass,
      client_id: signupDetails.data.cust_account_id,
      confirmation_code: otp,
    };

    await axios
      .post(`${devApi}/api/validateOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          handlePageChange();
          setVerifyOtpLoad(false);
          setShowEmailVerifyModal(true);
          setOtp();
          setOtpsent(false);
          setVerifyEmail(false);
          setSeconds(30);
          setSignupdata({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            confirm_password: "",
          });
          setSignupErrors({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            confirm_password: "",
          });
          setStrength();
        } else {
          setVerifyOtpLoad(false);
          setVerifyEmailErrors({
            ...verifyEmailErrors,
            otpError: response.data.message,
          });
        }
      })
      .catch((error) => {
        setVerifyEmailErrors({
          ...verifyEmailErrors,
          otpError: error.response.data.message,
        });
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };

  const remainingSeconds = seconds % 60;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setOtpErrors({ ...otpErrors, otpInvalid: "" });
    setSeconds(30);
    setOtpsent(false);
    setShowModal(false);
  };
  const closeEmailVerifyModal = () => {
    setShowEmailVerifyModal(false);
    setLoginPage(true);
  };
  const handleViewForgetPassword = () => {
    setLoginPage(false);
    setDisable(false);
    setMessages({ ...messages, emailError: "", emailSuccess: "" });
    setData({
      email: "",
    });
    setViewSignup(false);
    setVerifyEmail(false);
    setViewForgotPassword(true);
  };
  const handleViewSignup = () => {
    setLoginPage(false);
    setSignupdata({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
    });
    setStrength();
    setViewSignup(true);
    setVerifyEmail(false);
    setViewForgotPassword(false);
  };
  const handleViewLogin = () => {
    setLoginPage(true);
    setViewSignup(false);
    setVerifyEmail(false);
    setViewForgotPassword(false);
  };
  const handlePageChange = () => {
    setLoginPage(true);
    setSignupdata({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
    });
    setViewForgotPassword(false);
    setViewSignup(false);
  };
  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (cancelLoad) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (checkoutLoad) {
    return (
      <div className="d-flex flex-column justify-content-center text-center align-items-center ndf">
        <span className="redirect-text ">
          You're Being Redirected to the Secure Payment Gateway
        </span>
        <span>Do not press the back button!</span>
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row my-5">
          {isLoggedIn === true ? (
            <div className="col-lg-7">
              <h2 className="payment-head mb-3">Billing Details </h2>
              <div>
                <label for="mobile" className="formLable">
                  Mobile Number
                </label>
                <div className="d-flex flex-row align-items-center col-sm-4 col-lg-12 col-md-6 ">
                  <div className="form-input4 text-center align-items-center">
                    <img src={us} alt="" className="img-fluid flag-img me-1" />{" "}
                    <small className="me-1 align-items-center">+1</small>
                  </div>
                  <input
                    id="mobile"
                    type="text"
                    className="form-input3 ms-1"
                    onChange={updateMobile}
                    value={mobile}
                    placeholder="Enter your mobile number"
                    disabled={disableField}
                  />

                  {disableField ? (
                    ""
                  ) : (
                    <button
                      className={
                        mobile || !disableField
                          ? "verifyBtn ms-4"
                          : "verify-btn-disable ms-4"
                      }
                      onClick={handleMobileVerify}
                      disabled={proceedLoading}
                    >
                      {proceedLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Proceed"
                      )}
                    </button>
                  )}
                </div>
                <div>
                  {mobileError && (
                    <small
                      className={
                        disableField ? "text-success ml-2" : "text-danger ml-2"
                      }
                    >
                      {mobileError}
                    </small>
                  )}
                </div>
                {signupdata.email ? (
                  <div className="my-2">
                    <label for="emailAddress" className="formLable">
                      Email
                    </label>

                    <div className="d-flex flex-row  align-items-center">
                      <input
                        id="emailAddress"
                        type="text"
                        className="form-input3"
                        onChange={updateMobile}
                        value={clientData ? clientData.email : signupdata.email}
                        placeholder="Enter your mobile number"
                        disabled={disableField}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="payment-border my-3"></div>
                <div
                  className="d-flex terms-condition justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h6 className="mx-3">Terms and Conditions</h6>
                  <MdKeyboardArrowDown
                    className="mx-3"
                    style={{ width: "24px", height: "24px", color: "#024DDF" }}
                  />
                </div>

                <div className="collapse terms-body" id="collapseExample">
                  <div className="terms-body terms-text card-body mx-2">
                    <ul>
                      <li>
                        Cancellation Policy: Tickets are NON-TRANSFERABLE and
                        NON-REFUNDABLE unless the event is canceled or
                        rescheduled.
                      </li>
                      <li>
                        Refund Policy: Refunds are offered only if an event is
                        canceled, rescheduled, or moved. Refunds go back to the
                        original payment mode.
                      </li>
                      <li>
                        In case of event cancellation by the organizer, platform
                        fees and processing fees are NON-REFUNDABLE.
                      </li>
                      <li>
                        If you have any concerns about event cancellations or
                        refunds, please contact the event organizer directly.
                        Only genuine mobile tickets with QR codes will be
                        accepted for entry.
                      </li>
                      <li>
                        Arrive at the event at least 30 minutes prior to avoid
                        rush at the check-in counter.
                      </li>
                      <li>
                        A standard admission ticket provides a single entry to
                        the venue. Once you leave, you cannot re-enter using the
                        same ticket.
                      </li>
                      <li>
                        The tickets purchased are subject to Eventgem’s Terms
                        and Conditions, Privacy Policy, and Cookie Policy.
                      </li>
                      <li>
                        Privacy Policy: Users must review and agree to the
                        privacy policy, which details data handling and
                        security.
                      </li>
                      <li>
                        Prohibited Activities: Users are prohibited from
                        engaging in illegal activities, violating intellectual
                        property rights, or using the service for unauthorized
                        commercial purposes.
                      </li>
                      <li>
                        Disclaimer: Services are provided "as is" without any
                        warranties. Eventgem is not responsible for any
                        third-party links or services.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex  terms-accept align-items-center ">
                  <input
                    className="form-check-input checkbox-inline ms-3 my-3"
                    type="checkbox"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label acceptText ms-2 my-3"
                    for="inlineRadio1"
                  >
                    By clicking here, I state that I have read and understood
                    the "Terms and Conditions".
                  </label>
                </div>
                {checkoutSuccess.agreeSuccess &&
                checkoutSuccess.emailSuccess &&
                checkoutSuccess.mobileSuccess &&
                totalTickets !== 0 ? (
                  <div className="d-flex flex-column justify-content-start mt-4">
                    <h5 className="confirm-text">
                      Are you sure you want to proceed to checkout?
                    </h5>
                    <p className="acceptText">
                      You are redirecting to the payment screen. Please modify
                      your tickets now, if any.
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-start mt-4">
                  <button
                    className="btn btn-secondary me-2"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleCheckout}
                    disabled={
                      !checkoutSuccess.agreeSuccess ||
                      !checkoutSuccess.emailSuccess ||
                      !checkoutSuccess.mobileSuccess ||
                      totalTickets == 0
                    }
                  >
                    Yes, Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : loginPage === true ? (
            <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page py-0">
              <p>Log into your account</p>
              <div className="d-flex justify-content-center align-items-center my-4 w-25">
                {loginUrlGoogle ? (
                  <a href={loginUrlGoogle}>
                    <TbBrandGoogle
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />
                  </a>
                ) : (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </div>
              <div className="d-flex justify-content-evenly align-items-center w-100">
                <div className="left-line"></div>
                <h6 className="or">Or</h6>
                <div className="right-line"></div>
              </div>
              <div className="form-box w-100">
                <div className="mb-3">
                  <label htmlFor="" className="label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Email"
                    name="email"
                    onChange={updateLoginData}
                    value={logindata.email}
                  />
                  <small className="text-danger ml-2">
                    {loginErrors.email}
                  </small>
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-input"
                    placeholder="Enter Password"
                    name="password"
                    onChange={updateLoginData}
                    value={logindata.password}
                  />
                  <small className="text-danger ml-2">
                    {loginErrors.password}
                  </small>
                </div>
                <Link onClick={() => handleViewForgetPassword()}>
                  <h6 className="forget-text">Forgot Password?</h6>
                </Link>
              </div>
              {loading === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="login-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button
                  className={
                    logindata.email === "" || logindata.password === ""
                      ? "login-btn-disable"
                      : "login-btn"
                  }
                  onClick={logins}
                >
                  Login
                </button>
              )}
              <h5>
                Don't have an account?{" "}
                <Link to="" onClick={() => handleViewSignup()}>
                  <span>Sign Up</span>
                </Link>
              </h5>
            </div>
          ) : viewForgotPassword === true ? (
            <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
              <p style={{ fontWeight: "600", marginTop: "65px" }}>
                Forgot password?
              </p>
              <div className="d-flex justify-content-evenly align-items-center w-100">
                <div className="left-line w-100 mx-4"></div>
              </div>
              <p className="mt-4 text-center">
                No worries, enter your email address and we'll send reset
                instructions
              </p>
              <div className="form-box w-100">
                <div className="mb-2">
                  <label htmlFor="" className="label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-input"
                    placeholder="Enter Email"
                    name="email"
                    onChange={updateData}
                    value={data.email}
                    disabled={disable}
                  />
                  <small className="text-danger ml-2">
                    {messages.emailError}
                  </small>
                  <p className="text-success ml-2 text-center">
                    {messages.emailSuccess}
                  </p>
                  {messages.emailSuccess && (
                    <p className="text-primary ml-2 text-center">
                      {messages.emailCheck}
                    </p>
                  )}
                </div>
              </div>
              {forgotPasswordLoad === true ? (
                <button className="login-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  className={
                    data.email === "" || disable
                      ? "login-btn-disable mt-2"
                      : "login-btn mt-2"
                  }
                  onClick={resetPassword}
                >
                  Reset Password
                </button>
              )}

              <h5>
                Remember password?{" "}
                <Link onClick={() => handleViewLogin()}>
                  <span>Login</span>
                </Link>
              </h5>
            </div>
          ) : viewSignup === true ? (
            <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page py-0">
              <p>Create new account</p>
              <div className="d-flex justify-content-center align-items-center my-4 w-25">
                {loginUrlGoogle ? (
                  <a href={loginUrlGoogle}>
                    <TbBrandGoogle
                      style={{
                        width: "32px",
                        height: "30px",
                      }}
                    />
                  </a>
                ) : (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </div>
              <div className="d-flex justify-content-evenly align-items-center w-100">
                <div className="left-line"></div>
                <h6 className="or">Or</h6>
                <div className="right-line"></div>
              </div>
              <div className="form-box w-100">
                {/*First Name Last Name*/}
                <div className="d-flex  mb-2 mt-2">
                  <div
                    className="d-inline flex-column mb-2 "
                    style={{ width: "50%" }}
                  >
                    <label htmlFor="" className="signup-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="signup-input-name"
                      placeholder="Enter First Name"
                      name="first_name"
                      onChange={updateSignupData}
                      value={signupdata.first_name}
                    />
                    <small className="text-danger ml-2">
                      {signupErrors.first_name}
                    </small>
                  </div>
                  <div
                    className="d-inline flex-column mb-2 ms-2"
                    style={{ width: "50%" }}
                  >
                    <label for="formLastName" className="signup-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="formLastName"
                      className="signup-input-name"
                      placeholder="Enter Last Name"
                      name="last_name"
                      onChange={updateSignupData}
                      value={signupdata.last_name}
                    />
                    <small className="text-danger ml-2">
                      {signupErrors.last_name}
                    </small>
                  </div>
                </div>

                {/*Mobile Number*/}
                <div className="mb-2">
                  <label htmlFor="" className="signup-label">
                    Mobile Number
                  </label>
                  <div className="d-flex">
                    {" "}
                    <div
                      className="form-input4 text-center align-items-center"
                      style={{ padding: "12px 12px" }}
                    >
                      <img
                        src={us}
                        alt=""
                        className="img-fluid flag-img me-1"
                        style={{
                          width: "24px",
                          height: "23px",
                          marginBottom: 0,
                        }}
                      />{" "}
                      <small className="me-1 align-items-center">+1</small>
                    </div>
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      className="signup-input ms-1"
                      onChange={updateSignupData}
                      value={signupdata.phone}
                      placeholder="Enter your mobile number"
                    />
                  </div>
                  <small className="text-danger ml-2">
                    {signupErrors.phone}
                  </small>
                </div>

                {/*Email */}
                <div className="mb-2">
                  <label htmlFor="" className="signup-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="signup-input"
                    placeholder="Enter Email"
                    name="email"
                    onChange={updateSignupData}
                    value={signupdata.email}
                  />
                  <small className="text-danger ml-2">
                    {signupErrors.email}
                  </small>
                </div>

                {/*Password*/}
                <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <label for="password" className="signup-label">
                      Password
                    </label>
                    <div className="ms-2">{strength}</div>
                  </div>
                  <div className="d-flex align-items-center signup-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="signup-input-password"
                      placeholder="Enter Password"
                      name="password"
                      onChange={(event) => {
                        updateSignupData(event);
                        setStrength(
                          evaluatePasswordStrength(event.target.value)
                        );
                      }}
                      onFocus={() => setTyping(true)}
                      onBlur={() => setTyping(false)}
                      value={signupdata.password}
                      id="password"
                      aria-describedby="showHide"
                    />
                    <span
                      className="show-hide"
                      id="showHide"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye show-hide"></i>
                      ) : (
                        <i
                          className="bi bi-eye-slash show-hide"
                          id="togglePassword"
                        ></i>
                      )}
                    </span>
                  </div>
                  {typing && passScore !== 5 && (
                    <small className="text-primary ml-2 me-2">
                      Use 8 or more characters with a mix of letters, numbers &
                      symbols
                    </small>
                  )}
                  <small className="text-danger ml-2">
                    {signupErrors.password}
                  </small>
                </div>

                {/*Confirm Password*/}
                <div className="mb-2">
                  <label for="confirm_password" className="signup-label">
                    Confirm Password
                  </label>
                  <div className="d-flex align-items-center signup-input">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="signup-input-password"
                      placeholder="Re-Enter Password"
                      name="confirm_password"
                      onChange={updateSignupData}
                      value={signupdata.confirm_password}
                    />
                    <span
                      className="show-hide"
                      id="showHide"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <i className="bi bi-eye show-hide"></i>
                      ) : (
                        <i className="bi bi-eye-slash show-hide"></i>
                      )}
                    </span>
                  </div>
                  <small className="text-danger ml-2">
                    {signupErrors.confirm_password}
                  </small>
                </div>
              </div>
              {loading === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="login-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button
                  className={
                    signupdata.name === "" ||
                    signupdata.email === "" ||
                    signupdata.password === "" ||
                    signupdata.confirm_password === ""
                      ? "login-btn-disable"
                      : "login-btn"
                  }
                  onClick={signup}
                >
                  Sign Up
                </button>
              )}
              <h5>
                Already have an Account?{" "}
                <Link to="" onClick={() => handlePageChange()}>
                  <span>Login</span>
                </Link>
              </h5>
            </div>
          ) : verifyEmail === true ? (
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 login-page">
              <p style={{ fontWeight: "600", marginTop: "65px" }}>
                Create new account
              </p>
              <div className="d-flex justify-content-evenly align-items-center w-100">
                <div className="left-line w-100 mx-4"></div>
              </div>
              {verifyEmailErrors.otpError && (
                <small className={"text-danger ml-2"}>
                  {verifyEmailErrors.otpError}
                </small>
              )}
              <p>
                <Link
                  onClick={() => {
                    setViewSignup(true);
                    setVerifyEmail(false);
                  }}
                  className="link-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
                >
                  Click here to change you email address
                </Link>
              </p>
              <p className="mt-4 text-center mb-2">
                Enter the 4-digit verification code sent to you at{" "}
                <strong></strong>
                {signupDetails.data.email}
              </p>
              <div className="otp-inputs">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
                <h6 className="d-flex justify-content-between w-100 mt-2">
                  <span
                    className={
                      remainingSeconds == 0
                        ? "ms-3 btn-link resend-link"
                        : "ms-3 resend-link-inactive"
                    }
                    onClick={handleResendEmailOtp}
                  >
                    Resend OTP?
                  </span>{" "}
                  <span
                    className="me-3 resend-link-inactive"
                    style={{ color: "#121212" }}
                  >
                    {remainingSeconds}
                  </span>
                </h6>
              </div>
              {verifyOtpLoad === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="login-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button
                  className={
                    /^[0-9]{4}$/.test(otp)
                      ? "login-btn mt-3"
                      : "login-btn-disable mt-3"
                  }
                  onClick={verifyEmailOtp}
                >
                  Verify
                </button>
              )}

              <h5>
                Already have an Account?{" "}
                <Link
                  to=""
                  onClick={() => {
                    setLoginPage(true);
                    setViewSignup(false);
                    setViewForgotPassword(false);
                    setVerifyEmail(false);
                  }}
                >
                  <span>Login</span>
                </Link>
              </h5>
            </div>
          ) : (
            ""
          )}
          <div className="col-lg-1 d-none d-lg-inline">
            <div className=" event-border"></div>
          </div>
          <>
            <TicketOrder eventData={eventData} ticketRef={ticketRef} />
          </>
        </div>
        <Modal show={showModal} onHide={() => closeModal()} centered size="lg">
          <Modal.Body>
            {loadMOdal ? (
              <div className="col-lg-12 col-md-12 col-ms-12 col-12 login-page">
                <div className="loader-box">
                  <span></span>
                  <span></span>
                </div>
              </div>
            ) : (
              <div className="col-lg-12 col-md-12 col-ms-12 col-12 login-page">
                <p className="mt-4 text-center mb-2">
                  Enter the 4-digit verification code sent to your mobile{" "}
                  <strong>{mobile}</strong>
                </p>
                <div>
                  {!otp && otpErrors.otpInvalid && (
                    <small className={"text-danger ml-2"}>
                      {otpErrors.otpInvalid}
                    </small>
                  )}
                </div>
                <div className="otp-inputs my-2">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  <h6 className="d-flex justify-content-between w-100 mt-2">
                    <span
                      className={
                        remainingSeconds == 0
                          ? "ms-3 resend-link"
                          : "ms-3 resend-link-inactive"
                      }
                      onClick={handleResendOtp}
                    >
                      Resend OTP?
                    </span>{" "}
                    <span
                      className="me-3 resend-link-inactive"
                      style={{ color: "#121212" }}
                    >
                      {remainingSeconds}
                    </span>
                  </h6>
                </div>
                {loading === true ? (
                  <button className="loading-btn mt-3">
                    <div className="containers">
                      <div className="bar"></div>
                    </div>
                    Verify
                  </button>
                ) : (
                  <button
                    className={
                      /^[0-9]{4}$/.test(otp)
                        ? "login-btn mt-3"
                        : "login-btn-disable mt-3"
                    }
                    onClick={verifyOtp}
                  >
                    Verify
                  </button>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={showEmailVerifyModal}
          onHide={() => closeEmailVerifyModal()}
          centered
          size="lg"
        >
          <Modal.Body>
            <div className="forgot-modal">
              <h2>Welcome to</h2>
              <div className="d-flex justify-content-center">
                <img
                  src={eventgemLogo}
                  alt=""
                  className=""
                  style={{ width: 300, height: 150 }}
                />
              </div>
              <p>
                Congratulations! Start exploring events you'll love.  No more
                FOMO with Eventgem
              </p>
              <div className="d-flex justify-content-center">
                <img src={forgotmodal} alt="" className="img-fluid" />
              </div>
              <div className="d-flex justify-content-center">
                <Link
                  className="login-btn my-2"
                  onClick={() => closeEmailVerifyModal()}
                >
                  Login
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
