import React, { useState } from "react";
import "./css/Organiser.css";
import org from "../../Assets/org.png";
import vippass from "../../Assets/vippass.png";
import localpass from "../../Assets/localpass.png";
import mainpost from "../../Assets/mainpost.png";
import Divider from "../../Assets/Divider.png";
import { FaInstagram } from "react-icons/fa6";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { IoMdArrowForward } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { MdOutlineLocationOn, MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  initializeTicketTypes,
  updateQuantity,
  getTotalTickets,
  getTotalCost,
  updateTicketCart,
} from "../../redux/ticket";
import { useEffect } from "react";
import store from "../../redux/store";
import sentenceCase from "../../utils/utilities";
import axios from "axios";
import moment from "moment";

export default function Organiser({ data, ticketOrder }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  // const initialTicketTypes = data.data.tickets.map(ticket => ({
  //   type: ticket.description,
  //   price: parseInt(parseFloat(ticket.price)),
  //   quantity: 1
  // }));
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector(getTotalCost);
  const totalTickets = useSelector(getTotalTickets);
  const dispatch = useDispatch();
  useEffect(() => {
    const loadInitialState = async () => {
      const storedState = localStorage.getItem("ticketState");
      if (
        storedState &&
        JSON.parse(storedState).ticketTypes[0].event_id === data.data.event.id
      ) {
        const initialState = JSON.parse(storedState);
        store.dispatch(initializeTicketTypes(initialState.ticketTypes));
      } else {
        try {
          const initialTicketTypes = data.data.tickets.map((ticket) => ({
            id: ticket.id,
            type: ticket.title,
            full_price: parseInt(parseFloat(ticket.full_price)),
            isEarlyBird: ticket.early_bird,
            price: parseInt(parseFloat(ticket.price)),
            quantity: 1,
            event_id: data.data.event.id,
          }));
          store.dispatch(initializeTicketTypes(initialTicketTypes));
          localStorage.setItem(
            "ticketState",
            JSON.stringify({ ticketTypes: initialTicketTypes })
          );
        } catch (error) {
          console.error("Error fetching initial state from API:", error);
        }
      }
    };
    loadInitialState();
  }, []);
  const handleProceed = async () => {
    navigate("/checkout");
  };
  // const [ticketTypes, setTicketTypes] = useState(initialTicketTypes);

  const handleDecrease = (index) => {
    dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (index) => {
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const { inputValue } = useParams();
  return (
    <>
      <div className="container">
        <div className="row d-flex justify-content-between my-4">
          <div
            className="col-lg-4 col-md-12 col-sm-12 col-12"
            ref={ticketOrder}
          >
            <div className="event-order-box">
              <div className="d-flex justify-content-between w-100">
                <h5>Order Summary</h5>
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <h5>{totalTickets}</h5>
                  <h6>Tickets</h6>
                </div>
              </div>
              <div className="under-ticket d-flex justify-content-between align-items-center">
                <img src={data.data.event.eventImages} alt={mainpost} />
                <div className="under-ticket1 ms-4">
                  <h6>{sentenceCase(data.data.event.title)}</h6>
                  <p>{sentenceCase(data.data.event.categoryName)}</p>
                  <p>
                    {moment(data.data.event.start_date).format(
                      "ddd DD, MMMM, YYYY"
                    )}{" "}
                    <GoDotFill
                      style={{ width: "8px", height: "8px", color: "#121212" }}
                    />{" "}
                    {moment(data.data.event.start_date).format("hh:mm A")}
                  </p>
                  <p>
                    <MdOutlineLocationOn
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#024DDF",
                      }}
                    />{" "}
                    {data.data.event.venue_name}
                  </p>
                </div>
              </div>
              {ticketTypes.map((ticketType, index) => (
                <div
                  className="d-flex align-items-center justify-content-between my-4"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={localpass}
                      alt=""
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div className="d-flex flex-column justify-items-center">
                      <h5 className="pass">{sentenceCase(ticketType.type)}</h5>

                      {ticketType.isEarlyBird ? (
                        <h4 className="earlyBirdText">(Early Bird Offer)</h4>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between text-center align-items-center">
                    {ticketType.isEarlyBird ? (
                      <div className="d-flex justify-content-between text-center align-items-center">
                        <h6 className="paycharge-early">
                          $ {ticketType.price}
                        </h6>{" "}
                        <del className="ms-1">
                          <h6 className="text-secondary">
                            $ {ticketType.full_price}
                          </h6>
                        </del>
                      </div>
                    ) : (
                      <h6 className="paycharge">$ {ticketType.price}</h6>
                    )}
                    <div className="quantity ms-1">
                      <button
                        className="minus"
                        onClick={() => handleDecrease(index)}
                        disabled={ticketType.quantity <= 0}
                      >
                        -
                      </button>
                      <input
                        className="input-box"
                        type="number"
                        value={ticketType.quantity}
                        onChange={(event) => handleQuantityChange(event, index)}
                      />
                      <button
                        className="plus"
                        onClick={() => handleIncrease(index)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="d-flex align-items-center justify-content-between my-4">
                <div className="d-flex align-items-center">
                <img src={vippass} alt="" style={{width:"24px",height:"24px"}}/>
                <h5 className="pass">{data.data.tickets[0].description}</h5>
                </div>

                <div>
                  <h6 className="paycharge">$   {data.data.tickets[0].price}</h6>
    <div className="quantity">
      <button className="minus" onClick={decreasevipValue} disabled={vipvalue <= 1}>-</button>
      <input className="input-box" type="number" value={vipvalue} onChange={handleVipChange} />
      <button className="plus" onClick={increasevipValue}>+</button>
    </div>
                </div>
            </div> */}

              {/* <div className="d-flex align-items-center justify-content-between my-4">
                <div className="d-flex align-items-center">
                <img src={localpass} alt="" style={{width:"24px",height:"24px"}}/>
                <h5 className="pass">{data.data.tickets[1].description}</h5>
                </div>
                <div>
            <h6 className="paycharge">$   {data.data.tickets[1].price}</h6>
    <div className="quantity">
      <button className="minus" onClick={decreaselocalValue} disabled={localvalue <= 1}>-</button>
      <input className="input-box" type="number" value={localvalue} onChange={handlelocalChange} />
      <button className="plus" onClick={increaselocalValue}>+</button>
          </div></div>*/}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Divider}
                alt=""
                style={{
                  width: "-webkit-fill-available",
                  backgroundColor: "#f5f5f5",
                }}
              />
            </div>
            <div className="event-order-box">
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className={
                    totalTickets == 0 ? "proceedbtn-dis " : "proceedbtn"
                  }
                  onClick={handleProceed}
                  disabled={totalTickets == 0}
                >
                  {" "}
                  Proceed
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column col-lg-7 col-md-12 col-sm-12 col-12 my-3">
            <h2 className="org-head">Organizer Details</h2>
            <div className="org-box mt-4">
              <div className="d-flex align-items-start justify-content-between w-100">
                <div className=" d-flex align-items-center">
                  <img src={data.data.organiser[0].logo_path_fullpath} alt="" />
                  <h6>{data.data.organiser[0].name}</h6>
                </div>
                <div className="">
                  <FaInstagram
                    style={{ width: "32px", height: "32px", color: "#024DDF" }}
                  />
                </div>
              </div>
              <h5>About</h5>
              <p>{data.data.organiser[0].about}</p>
            </div>
            <div className="mt-5">
              <Link to="/organiserT&C">
                <button className="direction-btn">
                  Terms and Conditions{" "}
                  <IoMdArrowForward style={{ width: "24px", height: "24px" }} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-between py-0">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Modal title
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeModal}
                  aria-label="Close"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "30px",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input type="text" className="form-control" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModal}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
// <h5>More Events by the Organizer</h5>
//            { /*<div className="w-100 data-organiser">
//               {data.data.other_events.map((item) => {
//                 return (
//                   <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                     <img src={org} alt="" />
//                     {/* <img src={item.bg_image_path ? item.bg_image_path : org} alt="" /> */}
//                     <h5>{item.title}</h5>
//                   </div>
//                 );
//               })}
//               {/* <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                   <img src={org1} alt="" />
//                   <h5>Lorem Ipsum is <br /> simply dummy </h5>
//               </div>
//               <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                   <img src={org2} alt="" />
//                   <h5>Lorem Ipsum is <br /> simply dummy </h5>
//               </div>
//               <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                   <img src={org3} alt="" />
//                   <h5>Lorem Ipsum is <br /> simply dummy </h5>
//               </div>
//               <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                   <img src={org4} alt="" />
//                   <h5>Lorem Ipsum is <br /> simply dummy </h5>
//               </div>
//               <div className="otherorg d-flex justify-content-center flex-column align-items-center">
//                   <img src={org5} alt="" />
//                   <h5>Lorem Ipsum is <br /> simply dummy </h5>
//               </div> */}
//             </div>*/}
