import React, { useImperativeHandle, useState } from "react";
import "./css/TicketOrder.css";

import { useNavigate } from "react-router-dom";
import localpass from "../../Assets/localpass.png";
import mainpost from "../../Assets/mainpost.png";
import Divider from "../../Assets/Divider.png";
import { GoDotFill } from "react-icons/go";
import { MdOutlineLocationOn } from "react-icons/md";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import {
  decreaseQuantity,
  getTotalCost,
  getTotalTickets,
  increaseQuantity,
  initializeTicketTypes,
  updateQuantity,
  updateTicketCart,
  getTaxes,
} from "../../redux/ticket";

import store from "../../redux/store";
import sentenceCase from "../../utils/utilities";
import { devApi } from "../../utils/constants";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);
export default function TicketOrder({ eventData, ticketRef }) {
  const [showModal, setShowModal] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    email: "",
    password: "",
    api: "",
  });
  const [loginPage, setLoginPage] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ticketData = useSelector((state) => state.tickets);
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector(getTotalCost);
  const totalTickets = useSelector(getTotalTickets);
  const [loading, setLoading] = useState(false);
  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const token = useSelector((state) => state.user.token);
  const checkout = useSelector((state) => state.user.checkout);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inputValue = urlParams.get("inputValue") || 21;
  const ticketCart = useSelector((state) => state.tickets.ticketCart);
  const taxes = useSelector(getTaxes);

  useEffect(() => {
    const loadInitialState = async () => {
      const storedState = localStorage.getItem("ticketState");
      if (
        storedState &&
        JSON.parse(storedState).ticketTypes[0].event_id ===
          eventData.data.event.id
      ) {
        const initialState = JSON.parse(storedState);
        store.dispatch(initializeTicketTypes(initialState.ticketTypes));
      } else {
        try {
          const initialTicketTypes = eventData.data.tickets.map((ticket) => ({
            id: ticket.id,
            type: ticket.title,
            full_price: parseFloat(ticket.full_price),
            isEarlyBird: ticket.early_bird,
            price: parseFloat(ticket.price),
            quantity: 1,
            event_id: eventData.data.event.id,
          }));
          store.dispatch(initializeTicketTypes(initialTicketTypes));
          localStorage.setItem(
            "ticketState",
            JSON.stringify({ ticketTypes: initialTicketTypes })
          );
        } catch (error) {}
      }
    };
    loadInitialState();
  }, []);

  useImperativeHandle(ticketRef, () => ({
    childMethod() {
      makePayment();
    },
  }));
  const makePayment = async () => {
    setLoading(true);
    var counts = {};
    const ticketData = ticketTypes.map((ticket) => {
      var key = `ticket_${ticket.id}`;
      counts = { ...counts, [key]: ticket.quantity };
      return ticket.id;
    });
    const body = {
      ...counts,
      event_id: eventData.data.event.id,
      tickets: ticketData,
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${devApi}/api/e/postValidateTickets`, body, headers)
      .then((response) => {
        if (response.data.success) {
          store.dispatch(updateTicketCart(response.data.data));
          const config = {
            headers: {
              Authorization: "Bearer " + token,
              "Content-type": "application/json",
            },
          };
          const body = {
            data: response.data.data,
            event_id: eventData.data.event.id,
          };
          axios
            .post(`${devApi}/api/e/ticketPaymentCheckout`, body, config)
            .then(async (response) => {
              if (response.data.success) {
                setLoading(false);
                window.location.replace(response.data.data.url);
              } else {
                setLoading(false);
                setErrors({ ...errors, api: response.data.message });
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        } else {
          setErrors({ ...errors, api: response.data.message });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  // const makePayment = async () => {
  //   // const stripe = await loadStripe(
  //   //   "pk_test_51OtXNRHmvCgLlDBK8OLt2EEMStjsw1x3qcejsSltEPpizQNgwtGRpwV24NTaue9anhjWKusyCoCofCaqptjcOZp200i3aaQXvf"
  //   // );
  //   //  ("ticket", ticketData);
  //   // const body = {
  //   //   ticketSummary: { ticketData, totalTickets, totalCost },
  //   // };
  //   localStorage.setItem("checkout", true);
  //   const config = {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Content-type": "application/json",
  //     },
  //   };
  //   const body = { data: { ...ticketCart }, event_id: eventData.data.event.id };
  //   await axios
  //     .post(
  //       "https://stage.api.eventgem.com/api/e/ticketPaymentCheckout",
  //       body,
  //       config
  //     )
  //     .then(async (response) => {
  //       window.location.replace(response.data.data.url);
  //     })
  //     .catch((err) => {
  //        ("reponse", err.response);
  //     });
  //   // const response = await fetch(
  //   //   "https://stage.api.eventgem.com/api/checkoutTicket",
  //   //   {
  //   //     method: "GET",
  //   //     headers: headers,
  //   //     //body: JSON.stringify(body),
  //   //   }
  //   // );

  //   // const session = await response.json();
  //   //  (session);
  //   //window.location.replace(session.data.url);
  //   // const result = stripe.redirectToCheckout({
  //   //   sessionId: session.id,
  //   // });

  //   // if (result.error) {
  //   //    (result.error);
  //   // }
  // };
  const handleDecrease = (index) => {
    dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (index) => {
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
  };

  const closeModal = () => {
    setShowModal(false);
  };
  if (loading) {
    return (
      <div className="col-lg-5 col-md-12 col-sm-12 col-12 event-order-box h-100">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 col-12 event-order-box my-3 ">
        <div className="d-flex justify-content-between w-100">
          <h5>Order Summary</h5>
          <div className="d-flex justify-content-center flex-column align-items-center total-count">
            <h5>{totalTickets}</h5>
            <h6>Tickets</h6>
          </div>
        </div>
        <div className="under-ticket d-flex justify-content-between align-items-center">
          <img
            src={eventData.data.event.eventImages}
            alt={mainpost}
            className="img-fluid ticket-img"
          />
          <div className="under-ticket1 ms-4">
            <h6>{sentenceCase(eventData.data.event.title)}</h6>
            <p>{sentenceCase(eventData.data.event.categoryName)}</p>
            <p>
              {moment(eventData.data.event.start_date).format(
                "ddd DD, MMMM, YYYY"
              )}{" "}
              <GoDotFill
                style={{ width: "8px", height: "8px", color: "#121212" }}
              />{" "}
              {moment(eventData.data.event.start_date).format("hh:mm A")}
            </p>
            <p>
              <MdOutlineLocationOn
                style={{
                  width: "24px",
                  height: "24px",
                  color: "#024DDF",
                }}
              />{" "}
              {eventData.data.event.venue_name}
            </p>
          </div>
        </div>
        {ticketTypes?.map((ticketType, index) => (
          <div
            className="d-flex align-items-center justify-content-between my-4"
            key={index}
          >
            <div className="d-flex align-items-center">
              <img
                src={localpass}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
              <div className="d-flex flex-column justify-items-center">
                <h5 className="pass">{sentenceCase(ticketType.type)}</h5>

                {ticketType.isEarlyBird ? (
                  <h4 className="earlyBirdText">(Early Bird Offer)</h4>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="d-flex justify-content-between text-center align-items-center">
              {ticketType.isEarlyBird ? (
                <div className="d-flex justify-content-between text-center align-items-center">
                  <h6 className="paycharge-early">$ {ticketType.price}</h6>{" "}
                  <del className="ms-1">
                    <h6 className="text-secondary">
                      $ {ticketType.full_price}
                    </h6>
                  </del>
                </div>
              ) : (
                <h6 className="paycharge">$ {ticketType.price}</h6>
              )}
              <div className="quantity ms-1">
                <button
                  className="minus"
                  onClick={() => handleDecrease(index)}
                  disabled={ticketType.quantity <= 0}
                >
                  -
                </button>
                <input
                  className="input-box"
                  type="number"
                  value={ticketType.quantity}
                  onChange={(event) => handleQuantityChange(event, index)}
                />
                <button className="plus" onClick={() => handleIncrease(index)}>
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
        {/*<div className="d-flex justify-content-between align-items-center convcharge">
          <p>Convenience Charges</p>
          <p> + $ 1</p>
      </div>*/}
        <div className="d-flex align-items-center justify-content-center">
          <img src={Divider} alt="" className="divider" />
        </div>
        {/*<div className="d-flex justify-content-between align-items-center my-3">
          <h6 className="promocode" onClick={openModal}>
            I Have a Promocode
          </h6>
          <MdKeyboardArrowDown
            style={{ width: "24px", height: "24px", color: "#024DDF" }}
          />
      </div>*/}
        <div className="d-flex justify-content-between align-items-center convcharge">
          <p>Processing Fee</p>
          <p> $ {taxes.processingFee.toFixed(2)}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center convcharge">
          <p>Platform Fee</p>
          <p> $ {taxes.bookingFee.toFixed(2)}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="amountpay">Amount Payable</h6>
          <h4 className="totalpay">
            $ {(totalCost + taxes.processingFee + taxes.bookingFee).toFixed(2)}
          </h4>
        </div>
        {errors.api ? (
          <small className="text-danger ml-2">{errors.api}</small>
        ) : (
          ""
        )}
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header justify-content-between py-0">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Modal title
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeModal}
                  aria-label="Close"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "30px",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input type="text" className="form-control" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModal}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// {
//   loading ? (
//     <div className="d-flex justify-content-center align-items-center">
//       <button className="proceedbtn" type="button" disabled>
//         <span
//           className="spinner-border spinner-border-sm"
//           role="status"
//           aria-hidden="true"
//         ></span>
//         <span className="sr-only">Loading...</span>
//       </button>
//     </div>
//   ) : (
//     <div className="d-flex justify-content-center align-items-center">
//       <button className="proceedbtn" onClick={makePayment}>
//         {" "}
//         Checkout
//       </button>
//     </div>
//   );
// }
