import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import paymentSuccess from "../../Assets/PaymentSuccess.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import sentenceCase from "../../utils/utilities";
import { GoDotFill } from "react-icons/go";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import mainpost from "../../Assets/mainpost.png";
import Qrcode from "../../Assets/Qrcode.png";
import pass from "../../Assets/localpass.png";
import cross from "../../Assets/Divider.png";
import moment from "moment";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
export default function PaymentSuccess() {
  const [sessionId, setSessionId] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    setSessionId(sessionId);
    const getOrderDetails = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
        },
      };
      const body = {
        session_id: sessionId,
      };
      await axios
        .post(`${devApi}/api/e/paymentSucess`, body, config)
        .then((response) => {
          setOrderDetails(response.data);
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    getOrderDetails();
  }, []);
  if (!orderDetails) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {orderDetails.customer ? (
        <div className="container">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center  mt-4">
              <div className="title text-center">
                {" "}
                Payment Completed Successfully
              </div>
              {/* <p className="mt-5">
                Thanks for your order{" "}
                <strong>{orderDetails.customer.first_name}</strong>
              </p> */}
              <p className="success-para text-center">
                Event tickets with QR code will be sent to{" "}
                <strong>{orderDetails.customer.email} </strong>shortly or you
                can also{" "}
                <a
                  className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  href={orderDetails.orders[0].ticketfullpath}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download your Ticket
                </a>{" "}
                here.
              </p>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 payment-box mb-4">
                <div className="event-order-box pb-0">
                  {/* <div className="d-flex flex-column justify-content-between align-items-center w-100">
                  <h5>Order Summary</h5>
                  <div className="d-flex justify-content-between align-items-center total-count t bg-success-subtle border p-2 success-border rounded-6">
                    <h5 className="ext-light-emphasis">
                      {orderDetails.amountdetails.total_ticket_quantity}
                    </h5>
                    <h6 className="ext-light-emphasis mx-2">Tickets Booked</h6>
                  </div>
                </div> */}
                  <div className="d-flex justify-content-between  align-items-center mb-2">
                    <h5 className="confirmed-h5">
                      {" "}
                      <FaRegCheckCircle size={20} color="#1EA133" /> Booking
                      Confirmed
                    </h5>
                    <div className="d-flex justify-content-center flex-column align-items-center total-count">
                      <h5>
                        {
                          orderDetails.orders[0].amountdetails
                            .total_ticket_quantity
                        }
                      </h5>
                      <h6>Tickets</h6>
                    </div>
                  </div>
                  {/* <div className="under-ticket d-flex flex-column justify-content-between align-items-center">
                  {orderDetails.eventimagefullpath ? (
                    <img src={orderDetails.eventimagefullpath} alt="" />
                  ) : (
                    <></>
                  )}

                  <div className="under-ticket1 text-center justify-content-center align-items-center mt-4">
                    <h6>{sentenceCase(orderDetails.events.title)}</h6>
                    <p>
                      <strong>{orderDetails.events.start_date} </strong>
                    </p>
                    <p>
                      <MdOutlineLocationOn
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#024DDF",
                        }}
                      />{" "}
                      {sentenceCase(orderDetails.events.venue_name)}
                    </p>
                  </div>
                </div> */}
                  <div className="under-ticket d-flex ">
                    <img
                      src={
                        orderDetails?.orders[0].event_ext.event_image_fullpath
                          ? orderDetails.orders[0].event_ext
                              .event_image_fullpath
                          : mainpost
                      }
                      alt=""
                      className="payment-img"
                    />
                    <div className="under-ticket1 ms-4">
                      <h6>
                        {sentenceCase(orderDetails.orders[0].event.title)}
                      </h6>
                      <p>
                        {orderDetails.orders[0].event.startday},{" "}
                        {orderDetails.orders[0].event.starttime}{" "}
                      </p>
                      <p>
                        <MdOutlineLocationOn
                          style={{
                            width: "24px",
                            height: "24px",
                            color: "#024DDF",
                          }}
                        />{" "}
                        {orderDetails.orders[0].event.venue_name}
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#f5f5f5", height: "35px" }}>
                  <img
                    src={cross}
                    alt=""
                    style={{ width: "-webkit-fill-available" }}
                  />
                </div>
                <div className="event-order-box">
                  {orderDetails.orders[0].order_items?.map(
                    (ticketType, index) => (
                      // <div
                      //   className="d-flex align-items-center justify-content-between my-4"
                      //   key={index}
                      // >
                      //   <div className=" row d-flex align-items-center">
                      //     {/* <img src={vippass} alt="" style={{width:"24px",height:"24px"}}/> */}
                      //     <h5 className="pass">{sentenceCase(ticketType.title)}</h5>
                      //   </div>
                      //   <div className="quantity ">{ticketType.qty}</div>
                      //   <h6 className="paycharge ">$ {ticketType.price}</h6>
                      // </div>
                      <>
                        <div className="d-flex justify-content-between align-items-center ticketcharge ">
                          <p>
                            {ticketType.title} x {ticketType.quantity}
                          </p>
                          <p>
                            {" "}
                            ${" "}
                            {(
                              ticketType.unit_price * ticketType.quantity
                            ).toFixed(2)}
                          </p>
                        </div>
                      </>
                    )
                  )}
                  <div className="d-flex justify-content-between align-items-center processcharge ">
                    <p>
                      Platform fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      {" "}
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.platform_fee
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center processcharge">
                    <p>
                      Processing fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.processing_fee
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center processcharge">
                    <h6>Total amount</h6>
                    <h4>
                      ${" "}
                      {orderDetails.orders[0].amountdetails.full_total.toFixed(
                        2
                      )}
                    </h4>
                  </div>
                </div>
                <div className="event-order-box my-2">
                  <div className="d-flex justify-content-between">
                    <div className="order-data">
                      <h5>Booking Date & Time: </h5>
                      <h6>
                        {moment(orderDetails.orders[0].created_at).format(
                          "ddd DD, MMMM YYYY hh:mm A"
                        )}
                      </h6>
                    </div>
                    <div className="order-data">
                      <h5>Booking ID:</h5>
                      <h6>{orderDetails.orders[0].order_reference}</h6>
                    </div>
                  </div>
                </div>
                <div className="qrcode-box text-center my-2">
                  <h6>
                    If you have any questions, email us at info@eventgem.com
                  </h6>
                  <h6>
                    or you can reach out to the organiser at +1(312) 123-4567{" "}
                  </h6>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="proceedbtn">
                    {" "}
                    <Link to={`/userhome/orderHistory`}>My Orders</Link>
                  </button>
                </div>
              </div>
              {/* <img
                alt=".."
                className="img-fluid"
                style={{ width: "550px", height: "300px" }}
                src={paymentSuccess}
              /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center ndf">
          <div className="loader-box">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </>
  );
}

// {
//     "success": true,
//     "message": "Stripe success response",
//     "customer": {
//         "id": 4,
//         "cust_account_id": 4,
//         "created_at": "2024-04-18 14:01:08",
//         "updated_at": "2024-05-15 19:58:50",
//         "deleted_at": null,
//         "first_name": "Sumant Thakre",
//         "last_name": "test",
//         "phone": null,
//         "country_code": null,
//         "email": "sumant.thakre@deployd.io",
//         "confirmation_code": "wf6kFe39neie56bH",
//         "is_registered": 1,
//         "is_confirmed": 0,
//         "is_parent": 1,
//         "api_token": "f45DsKbgCPyW5ygDUh9ATGUcI3nKXVAwl4WkquHiwwqgcHzxzm0Bh32YyzJS",
//         "social_id": null,
//         "social_type": null,
//         "confirm_code_exp": null,
//         "reset_password_exp": "2024-05-15 19:58:50",
//         "mobile_otp": "1445"
//     },
//     "orders": [
//         {
//             "id": 106,
//             "account_id": 11,
//             "order_status_id": 1,
//             "created_at": "2024-05-15 19:59:54",
//             "updated_at": "2024-05-15 19:59:54",
//             "deleted_at": null,
//             "first_name": "Sumant Thakre",
//             "last_name": "test",
//             "email": "sumant.thakre@deployd.io",
//             "phone": null,
//             "business_name": null,
//             "business_tax_number": null,
//             "business_address_line_one": null,
//             "business_address_line_two": null,
//             "business_address_state_province": null,
//             "business_address_city": null,
//             "business_address_code": null,
//             "ticket_pdf_path": null,
//             "order_reference": "R8tTz155",
//             "transaction_id": "evt_1PGnzSHmvCgLlDBK3lvf74aW",
//             "discount": "0.00",
//             "booking_fee": "19.20",
//             "organiser_booking_fee": "30.00",
//             "order_date": null,
//             "notes": null,
//             "is_deleted": 0,
//             "is_cancelled": 0,
//             "is_partially_refunded": false,
//             "is_refunded": false,
//             "amount": "600.00",
//             "amount_refunded": null,
//             "event_id": 21,
//             "payment_gateway_id": 1,
//             "is_payment_received": 1,
//             "is_business": false,
//             "taxamt": 0,
//             "payment_intent": "pi_3PGnzQHmvCgLlDBK1XZY8NYM",
//             "eventext_id": 14,
//             "cust_id": 4,
//             "remind_1": 0,
//             "remind_2": 0,
//             "remind_3": 0,
//             "remind_4": 0,
//             "ticketfullpath": "https://stage.api.eventgem.com/user_content/pdf_tickets/R8tTz155.pdf",
//             "ticketQRcode": "https://stage.api.eventgem.com/user_content/pdf_tickets/R8tTz155.png",
//             "artist": {
//                 "id": 2,
//                 "name": "Kabir Khan",
//                 "category_id": 5,
//                 "user_id": 1,
//                 "account_id": 1,
//                 "email": "jai.artist@deployd.io",
//                 "phone": "1111111111",
//                 "description": "test description",
//                 "image_path": "user_content/event_images/artist_image_0b86e7c4094decb410637b6ac78e4b08.jpeg",
//                 "img_full_path": "https://stage.api.eventgem.com/attendize/user_content/event_images/artist_image_0b86e7c4094decb410637b6ac78e4b08.jpeg",
//                 "fb_link": "test",
//                 "insta_link": "test",
//                 "youtube_link": "test",
//                 "twitter_link": "test",
//                 "created_at": "2024-03-19 19:50:23",
//                 "updated_at": "2024-03-27 12:05:38",
//                 "deleted_at": null,
//                 "organiser_id": 1
//             },
//             "category": [
//                 "Music"
//             ],
//             "amountdetails": {
//                 "processing_fee": "19.20",
//                 "platform_fee": "30.00",
//                 "full_total": 649.2,
//                 "total_booking_fee": 49.2,
//                 "order_total": "600.00",
//                 "total_ticket_quantity": 6
//             },
//             "event": {
//                 "id": 21,
//                 "title": "Garba six",
//                 "location": null,
//                 "bg_type": "image",
//                 "bg_color": "#B23333",
//                 "bg_image_path": "assets/images/public/EventPage/backgrounds/5.jpg",
//                 "description": "test",
//                 "start_date": "2024-04-18 23:54:00",
//                 "end_date": "2024-04-22 23:54:00",
//                 "on_sale_date": null,
//                 "account_id": 11,
//                 "user_id": 13,
//                 "currency_id": 2,
//                 "artist_id": null,
//                 "organiser_fee_fixed": "0.00",
//                 "organiser_fee_percentage": "5.00",
//                 "organiser_id": 10,
//                 "venue_name": "philadelphia",
//                 "venue_name_full": null,
//                 "location_address": null,
//                 "location_address_line_1": null,
//                 "location_address_line_2": null,
//                 "location_country": null,
//                 "location_country_code": null,
//                 "location_state": null,
//                 "location_post_code": "94568",
//                 "location_street_number": null,
//                 "location_lat": null,
//                 "location_long": null,
//                 "location_google_place_id": null,
//                 "pre_order_display_message": null,
//                 "post_order_display_message": null,
//                 "social_share_text": null,
//                 "social_show_facebook": 1,
//                 "social_show_linkedin": 1,
//                 "social_show_twitter": 1,
//                 "social_show_email": 1,
//                 "location_is_manual": 1,
//                 "is_live": 1,
//                 "created_at": "2024-04-18 18:24:44",
//                 "updated_at": "2024-04-18 18:25:14",
//                 "deleted_at": null,
//                 "barcode_type": "QRCODE",
//                 "ticket_border_color": "#000000",
//                 "ticket_bg_color": "#FFFFFF",
//                 "ticket_text_color": "#000000",
//                 "ticket_sub_text_color": "#999999",
//                 "google_tag_manager_code": null,
//                 "social_show_whatsapp": 1,
//                 "questions_collection_type": "buyer",
//                 "checkout_timeout_after": 8,
//                 "is_1d_barcode_enabled": 0,
//                 "enable_offline_payments": 0,
//                 "offline_payment_instructions": null,
//                 "event_image_position": null,
//                 "startday": "Thu, 18th April, 2024",
//                 "starttime": "11:54 PM",
//                 "endDay": "Mon, 22nd April, 2024",
//                 "endTime": "11:54 PM"
//             },
//             "tickets": [
//                 {
//                     "id": 20,
//                     "created_at": "2024-04-18 18:25:05",
//                     "updated_at": "2024-05-15 19:59:54",
//                     "deleted_at": null,
//                     "edited_by_user_id": null,
//                     "account_id": 11,
//                     "order_id": null,
//                     "event_id": 21,
//                     "title": "SIVER",
//                     "description": "",
//                     "price": "100.00",
//                     "max_per_person": 30,
//                     "min_per_person": 1,
//                     "quantity_available": 200,
//                     "quantity_sold": 143,
//                     "start_sale_date": "2024-04-18 18:25:00",
//                     "end_sale_date": null,
//                     "sales_volume": "14300.00",
//                     "organiser_fees_volume": "1625.00",
//                     "is_paused": 0,
//                     "public_id": null,
//                     "user_id": 13,
//                     "sort_order": 0,
//                     "is_hidden": 0,
//                     "eventext_id": 14,
//                     "early_bird": 0,
//                     "full_price": "0.00",
//                     "pivot": {
//                         "order_id": 106,
//                         "ticket_id": 20
//                     }
//                 },
//                 {
//                     "id": 23,
//                     "created_at": "2024-04-23 17:46:01",
//                     "updated_at": "2024-05-15 19:59:54",
//                     "deleted_at": null,
//                     "edited_by_user_id": null,
//                     "account_id": 11,
//                     "order_id": null,
//                     "event_id": 21,
//                     "title": "GOLD",
//                     "description": "",
//                     "price": "100.00",
//                     "max_per_person": 30,
//                     "min_per_person": 1,
//                     "quantity_available": 200,
//                     "quantity_sold": 145,
//                     "start_sale_date": "2024-04-23 17:46:00",
//                     "end_sale_date": null,
//                     "sales_volume": "14500.00",
//                     "organiser_fees_volume": "1645.00",
//                     "is_paused": 0,
//                     "public_id": null,
//                     "user_id": 13,
//                     "sort_order": 0,
//                     "is_hidden": 0,
//                     "eventext_id": 14,
//                     "early_bird": 0,
//                     "full_price": "0.00",
//                     "pivot": {
//                         "order_id": 106,
//                         "ticket_id": 23
//                     }
//                 }
//             ],
//             "order_items": [
//                 {
//                     "id": 178,
//                     "title": "SIVER",
//                     "quantity": 4,
//                     "unit_price": "100.00",
//                     "unit_booking_fee": "32.80",
//                     "order_id": 106,
//                     "deleted_at": null
//                 },
//                 {
//                     "id": 179,
//                     "title": "GOLD",
//                     "quantity": 2,
//                     "unit_price": "100.00",
//                     "unit_booking_fee": "16.40",
//                     "order_id": 106,
//                     "deleted_at": null
//                 }
//             ],
//             "event_ext": {
//                 "id": 14,
//                 "event_id": 21,
//                 "category_id": null,
//                 "artist_id": 2,
//                 "event_image_fullpath": "",
//                 "event_timezone": "America/Adak",
//                 "created_at": "2024-04-18 18:24:44",
//                 "updated_at": "2024-04-18 18:24:44",
//                 "zero_platform_fee": 0,
//                 "zero_platform_fee_seats": 0,
//                 "cc_processing": "3.20"
//             }
//         }
//     ]
// }
