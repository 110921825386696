import { useEffect, useRef, useState } from "react";

// Styles
import "./css/QrScanner.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../Assets/qr-frame.svg";
import localpass from "../../Assets/localpass.png";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import closeIcon from "../../Assets/close.svg";
import qrIcon from "../../Assets/qrIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import ticketNumber from "../../Assets/ticketNumber.svg";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { DiVisualstudio } from "react-icons/di";
import userPhoto from "../../Assets/userPhoto.png";
import moment from "moment";

// "#D9D9D9"
// "#024DDF"
const DoorManager = () => {
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [videoElCurrent, setVideoElCurrent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [attendeeData, setAttendeeData] = useState();
  const [message, setMessage] = useState();
  const [result, setResult] = useState(false);
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmFwaS5ldmVudGdlbS5jb21cL2FwaVwvZG1cL2Rvb3JNYW5hZ2VyTG9naW4iLCJpYXQiOjE3MTc2Nzc2MzAsImV4cCI6MTcxNzcxMzYzMCwibmJmIjoxNzE3Njc3NjMwLCJqdGkiOiI1SWh4bUxoWmJMMmthSWlRIiwic3ViIjoxLCJwcnYiOiI2NmZmOGIxOWRkZDAyZWFmODU5NDhlOTI3MzI2YjYwZDRhYjM3NDVhIn0.AnMie33qnCwILpdNJgAkcVg5EZcIRSdUFDLAReq_1P0";
  // Result
  const [scannedResult, setScannedResult] = useState("");

  // Success
  const onScanSuccess = async (result) => {
    scanner.current.stop();
    setScannedResult(result?.data);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const body = {
      qr_code: result.data,
    };

    await axios
      .post(`${devApi}/api/dm/scanAttendeeQR`, body, config)
      .then((response) => {
        if (response.data.success) {
          setAttendeeData(response.data.attendee);
          setMessage();
          setShowModal(true);
          setResult(true);
        } else {
          setAttendeeData();
          setResult(false);
          setShowModal(true);
          setMessage(response.data.message);
          scanner.current.stop();
        }
      })
      .catch((error) => {});
  };

  // Fail
  const onScanFail = (err) => {};
  const scan = () => {
    scanner.current.start();
    setShowModal(false);
  };
  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      setVideoElCurrent(true);
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    } else {
      setVideoElCurrent(false);
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoElCurrent) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader d-flex flex-column align-items-center justify-content-center">
      <div className="navbar fixed-top navbar-light bg-light justify-content-between">
        <div className="m-2 mx-lg-4">
          <img
            src={eventgemLogo}
            alt="Company Logo"
            className="img-fluid "
            fetchpriority="high"
          />
        </div>
        <div className="m-2 mx-lg-4">
          <span className="option-text">Hi</span>{" "}
          <img src={userPhoto} alt="" className="img-fluid user-nav-img " />{" "}
        </div>
      </div>
      {/* QR */}
      <div>
        <video ref={videoEl} className="video-box"></video>
        <div
          ref={qrBoxEl}
          className="qr-box align-items-center justify-content-center"
        >
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={256}
            height={256}
            className="qr-frame"
          />
        </div>
      </div>
      <div className="navbar fixed-bottom navbar-light bg-light  justify-content-around ">
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <img src={ticketNumber} alt="" />
          <h5 className="option-text">Ticket Status</h5>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <img src={qrIcon} alt="" />
          <h5 className="option-text">Scanner</h5>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <img src={closeIcon} alt="" />
          <h5 className="option-text">Close Entries</h5>
        </div>
      </div>

      {showModal ? (
        <div className="d-flex justify-content-between  flex-column align-items-center mb-2 ">
          <div className="overlayScan">
            <div classNmae="matter">
              {result ? (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2 result-header">
                    <div className="d-flex  justify-items-center align-items-center ">
                      <FaRegCheckCircle size={24} color="#1EA133" />{" "}
                      <h5 className="successScan ms-2">Success</h5>
                    </div>
                    <button
                      className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                      style={{ borderRadius: 20 }}
                      onClick={scan}
                    >
                      <small className="resultButtons">Scan Next</small>
                      <IoIosArrowForward />
                    </button>
                  </div>
                  <div className="d-flex  flex-column mx-4">
                    <div className="d-flex justify-content-between flex-row align-items-center my-2">
                      <h5 className="attendee">
                        {attendeeData?.first_name} {attendeeData?.last_name}
                      </h5>
                      <small className="attendee-ticket">
                        {moment(attendeeData?.arrival_time).format("hh:mm A")}
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <img
                          src={localpass}
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />

                        <h5 className="attendee-ticket ms-2">
                          General Admission
                        </h5>
                      </div>

                      <div className="d-flex justify-content-between text-center align-items-center">
                        <div className="d-flex justify-content-between text-center align-items-center">
                          <h6 className="ticket-id">
                            Ticket Id : {attendeeData?.ticket_id}
                          </h6>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2 result-header">
                  <div className="d-flex  justify-items-center align-items-center ">
                    <RxCrossCircled size={24} color="#FF4C32" />{" "}
                    <h5 className="failedScan ms-2">Failed</h5>
                  </div>
                  <button
                    className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                    style={{ borderRadius: 20 }}
                    onClick={scan}
                  >
                    <small className="resultButtons">Try Again</small>
                  </button>
                </div>
              )}
              {message && (
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <small className="text ml-2">{message}</small>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DoorManager;
