import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Nav,
  Container,
  Offcanvas,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import "./css/Profile.css";
// import "../../Components/EventDetails/css/Header.css";
import Profile from "./Profile";
import OrderHistory from "./OrderHistory";
import { FaBars } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

export default function ProfileHome() {
  const [mobilenavbar, setMobilenavbar] = useState(false);
  const [orderHistory, setOrderHistory] = useState(false);
  const [profile, setProfile] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("orderHistory")) {
      setOrderHistory(true);
    }
    if (location.pathname.includes("profile")) {
      setProfile(true);
    }
  }, []);
  return (
    // <React.Fragment>
    //   <div>
    //     <Navbar
    //       key={"sm"}
    //       bg={"#024DDF"}
    //       expand={"sm"}
    //       className="mb-5 nav-pills nav-active-link"
    //     >
    //       <Container fluid>
    //         <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
    //         <Navbar.Offcanvas id={`offcanvasNavbar-expand-sm`} placement="end">
    //           <Offcanvas.Header closeButton></Offcanvas.Header>
    //           <Offcanvas.Body>
    //             <Container>
    //               <Nav
    //                 className="mr-auto my-2 my-lg-0 justify-content-start align-item-center nav-pills nav-active-link"
    //                 style={{ maxHeight: "100px" }}
    //               >
    //                 <Nav.Link as={Link} to="profile">
    //                   Profile
    //                 </Nav.Link>
    //                 <Nav.Link as={Link} to="orderHistory">
    //                   Order History
    //                 </Nav.Link>
    //               </Nav>
    //             </Container>
    //           </Offcanvas.Body>
    //         </Navbar.Offcanvas>
    //       </Container>
    //     </Navbar>
    //   </div>
    //   <div>
    //     <Outlet />
    //   </div>
    // </React.Fragment>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12  p-0 my-3 d-none d-lg-block">
          <ul
            className="nav nav-pills mb-3 align-items-center justify-content-center "
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                className={
                  profile
                    ? "profile-tab nav-link rounded-0 active"
                    : "profile-tab nav-link rounded-0 "
                }
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Profile
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={
                  orderHistory
                    ? "profile-tab nav-link rounded-0 active"
                    : "profile-tab nav-link rounded-0 "
                }
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Order History
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={
                profile ? "tab-pane fade show active" : "tab-pane fade"
              }
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <Profile />
            </div>
            <div
              className={
                orderHistory
                  ? "tab-pane fade my-4 show active"
                  : "tab-pane fade  my-4"
              }
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <OrderHistory />
            </div>
          </div>
        </div>
        <>
          <header className="d-block d-lg-none">
            <div className="container d-flex align-items-center p-0">
              <h1 className="logo me-auto"></h1>
              <nav
                id="navbar"
                className={
                  mobilenavbar === false
                    ? "navbar"
                    : "navbar-mobile nav-active "
                }
              >
                <ul className="my-data">
                  <Link
                    to="/userhome/profile"
                    onClick={() => setMobilenavbar(false)}
                  >
                    <li className="d-block py-2 text-black">My Profile</li>
                  </Link>
                  <Link
                    to="/userhome/orderHistory"
                    onClick={() => setMobilenavbar(false)}
                  >
                    <li className="d-block py-2 text-black">My Orders</li>
                  </Link>
                </ul>
                {mobilenavbar === false ? (
                  <FaBars
                    className="data-nav-toggle"
                    onClick={() => setMobilenavbar(true)}
                  />
                ) : (
                  <RxCross2
                    className="data-nav-toggle"
                    onClick={() => setMobilenavbar(false)}
                  />
                )}
              </nav>
            </div>
            <div className="col-12">
              {location.pathname === "/userhome/profile" ? (
                <Profile />
              ) : (
                <OrderHistory />
              )}
            </div>
          </header>
        </>
      </div>
    </div>
  );
}
