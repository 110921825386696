import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomePage from "./Components/EventDetails/HomePage";
import Login from "./Components/Login/Login";
import Signup from "./Components/Login/Signup";
import EventLogin from "./Components/Login/EventLogin";
import Header from "./Components/EventDetails/Header";
import Footer from "./Components/EventDetails/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Components/Profile/Profile";
import { useSelector } from "react-redux";
import PageNotFound from "./Components/Error/PageNotFound";
import PaymentSuccess from "./Components/Payments/PaymentSuccess";
import PaymentFailed from "./Components/Payments/PaymentFailed";
import ProfileHome from "./Components/Profile/ProfileHome";
import OrderHistory from "./Components/Profile/OrderHistory";
import Home from "./Components/Home/Home";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import GoogleCallback from "./Components/Login/SocialLogin";
import ResetPassword from "./Components/ForgotPassword/ResetPassword";
import TermsAndConditions from "./Components/FooterLegalData/TermsAndConditions";
import CookiePolicy from "./Components/FooterLegalData/CookiePolicy";
import FoorterDetails from "./Components/EventDetails/FooterDetails";
import NewHome from "./Components/NewHome/NewHome";
import PrivacyPolicy from "./Components/FooterLegalData/PrivacyPolicy";
import HeaderDetails from "./Components/EventDetails/HeaderDetails";
import VerifyEmail from "./Components/Login/VerifyEmail";
import Render from "./Components/ViewSetter/Render";
import FAQ from "./Components/FooterLegalData/FAQ";
import OrgTermsAndConditions from "./Components/FooterLegalData/OrgTermsAndConditions";
import BackToTop from "./Components/ViewSetter/BackToTop";
import DoorManager from "./Components/DoorManagement/DoorManager";
import LoginDM from "./Components/DoorManagement/LoginDM";
import FacebookPolicy from "./Components/FooterLegalData/FacebookPolicy";
const App = () => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const eventId = useSelector((state) => state.user.eventId);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const checkout = localStorage.getItem("checkout");
  useEffect(() => {
    const handleUrlChange = () => {
      setCurrentUrl(window.location.pathname);
    };
    window.addEventListener("popstate", handleUrlChange);
    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, []);

  // useEffect(() => {
  //   if (isLoggedIn && eventId && !checkout) {
  //     const expectedPath = `/event/${eventId}`;
  //     if (currentUrl !== expectedPath) {
  //       window.history.pushState(null, "", expectedPath);
  //       setCurrentUrl(expectedPath);
  //       window.location.reload();
  //     }
  //   }
  // }, [currentUrl, eventId, isLoggedIn, checkout]);

  return (
    <>
      <BrowserRouter>
        <BackToTop />
        <Render />
        <HeaderDetails />
        <Routes>
          {/*<Route exact path="/" element={<Home />} />*/}
          <Route exact path="/" element={<NewHome />} />
          <Route path="/event/:inputValue" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/checkout" element={<EventLogin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/paymentSuccess" element={<PaymentSuccess />} />
          <Route path="/paymentFailed" element={<PaymentFailed />} />
          <Route path="/doormanagement" element={<LoginDM />} />
          <Route path="/scanner" element={<DoorManager />} />
          <Route path="/userhome" element={<ProfileHome />}>
            <Route path="profile" element={<Profile />} />
            <Route path="orderHistory" element={<OrderHistory />} />
            <Route path="notifications" element={<Profile />} />
          </Route>
          <Route path="/resetpassword/*" element={<ResetPassword />} />
          <Route
            path="/termsandconditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/facebookPolicy" element={<FacebookPolicy />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/cookiepolicy" element={<CookiePolicy />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route
            path="/organiserT&C"
            element={<OrgTermsAndConditions />}
          ></Route>
          <Route path="/api/*" element={<GoogleCallback />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <FoorterDetails />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
