import React from "react";
import "./css/Banner.css";
import {
  MdKeyboardArrowLeft,
  MdOutlineShare,
  MdOutlineLocationOn,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { FaRegBookmark, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TbBolt } from "react-icons/tb";
import { LuCalendar } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import {
  IoIosInformationCircleOutline,
  IoMdArrowForward,
  IoMdAlarm,
} from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerimg from "../../Assets/bannerimg.png";
import artistimg from "../../Assets/artistimg.png";
import menuicon from "../../Assets/menuicon.svg";
import wallet from "../../Assets/wallet.svg";
import Ticket from "../../Assets/Ticket.svg";
import sentenceCase from "../../utils/utilities";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Banner({ data, ticketOrder }) {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
        },
      },
    ],
  };
  const handleBuyNow = (e) => {
    e.preventDefault();
    ticketOrder.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-between my-3">
          {/* <div className="d-flex align-items-center">
            <MdKeyboardArrowLeft
              style={{ width: "24px", height: "24px", color: "#121212" }}
            />
            <h2 className="back-btn ms-2">Go Back</h2>
          </div>
          <div className="d-flex">
            <div className="icon-box me-4">
              <FaRegBookmark
                style={{ width: "24px", height: "19px", color: "#121212" }}
              />
            </div>
            <div className="icon-box me-3">
              <MdOutlineShare
                style={{ width: "24px", height: "22px", color: "#121212" }}
              />
            </div>
  </div>*/}
        </div>
        <div className="col-lg-12 slide-main-box">
          <Slider {...settings}>
            {data.data.banners?.map((image, index) => {
              return (
                <div className="slick-img">
                  <img
                    id={index}
                    src={image.banner_img_fullpath}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px" }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="row justify-content-between my-5">
        <div className="col-lg-7 datas-box">
          <div className="d-flex align-items-center mb-4">
            <h3 className="title">{sentenceCase(data.data.event.title)}</h3>
            {data.data.event.isFillingFast ? (
              <div className="filling-tab">
                <TbBolt
                  style={{ width: "24px", height: "24px", color: "#121212" }}
                />
                <h4>Filling Fast</h4>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="icon-box me-3">
              <img src={menuicon} style={{ width: "24px", height: "24px" }} />
            </div>
            <h6 className="info">
              {sentenceCase(data.data.event.categoryName)}
            </h6>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="icon-box me-3">
              <LuCalendar
                style={{ width: "24px", height: "24px", color: "#121212" }}
              />
            </div>
            <h6 className="info">{data.data.event.startday}</h6>
            <div className="mx-2">
              <GoDotFill
                style={{ width: "10px", height: "10px", color: "#121212" }}
              />
            </div>
            <h6 className="info">
              {moment(data.data.event.start_date).format("hh:mm A")}
            </h6>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="icon-box me-3">
              <MdOutlineLocationOn
                style={{ width: "24px", height: "24px", color: "#121212" }}
              />
            </div>
            <h6 className="info">{data.data.event.venue_name}</h6>
          </div>
          {/*<div className="d-flex align-items-center mb-3">
            <div className="icon-box me-3">
              <img src={wallet} style={{ width: "24px", height: "24px" }} />
            </div>
            <h6 className="info">Starts from $7.00</h6>
          </div>*/}
          <div className="d-flex align-items-center bidding-offer">
            {data.data.event.early_bird ? (
              <>
                {" "}
                <IoMdAlarm
                  style={{ width: "24px", height: "24px", color: "#23BA20" }}
                />
                <h4>Early Bird Offer </h4>
                <span>( Ends on {data.data.event.early_bird_end_date})</span>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="info-data">
            <h5>About</h5>
            {/* <p>{data.data.event.description} </p> */}
            <p>{data.data.event.description}</p>
          </div>
          {/*<div className="d-flex align-items-center">
            <img src={Ticket} style={{ width: "22px", height: "22px" }} />
            <h4 className="ticket-availability">
              Box Office tickets Available
            </h4>
            <IoIosInformationCircleOutline
              style={{ width: "12px", height: "12px" }}
            />
          </div>*/}
          <div className="info-data">
            <h5>Venue</h5>
            <p>
              {data.data.event.venue_name}
              {/* {data.data.event.location_address}  {data.data.event.location_address_line_1} {data.data.event.location_address_line_2} */}
              <br />
              {data.data.event.location_address_line_1} <br />
              {data.data.event.location_address_line_2}
            </p>
          </div>
          <div className="direction-btn">
            Get Directions
            <IoMdArrowForward style={{ width: "24px", height: "24px" }} />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end flex-column align-items-end">
            <button className="buynow-btn" onClick={handleBuyNow}>
              Buy Now
            </button>
          </div>
          <h5 className="artist-head">Know the Artist</h5>
          <div className="d-flex justify-content-end flex-column align-items-end">
            <div className="artist-detail">
              <div className="row d-flex align-items-center">
                <div className="col-6 artist-img">
                  <img src={data.data.artists.img_full_path} alt="" />
                </div>
                <div className="col-6 artist-info">
                  <h6>{data.data.artists.categoryName}</h6>
                  <h4>{data.data.artists.name}</h4>
                </div>
                <div className="col-12 artist-desc mt-3">
                  <h6>About Artist</h6>
                  <p>
                    {" "}
                    {data.data.artists.description === ""
                      ? `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.`
                      : data.data.artists.description}
                  </p>
                  {/* <h6>
                    Upcoming Tour{" "}
                    <MdKeyboardArrowRight
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#024DDF",
                      }}
                    />
                    </h6>*/}
                </div>
                {/*<div className="col-4 artistsimg">
                  <img src={artistimg} alt="" />
                  <p>Lorem Ipsum</p>
                </div>
                <div className="col-4 artistsimg">
                  <img src={artistimg} alt="" />
                  <p>Lorem Ipsum</p>
                </div>
                <div className="col-4 artistsimg">
                  <img src={artistimg} alt="" />
                  <p>Lorem Ipsum</p>
                    </div>*/}
                <div className="col-12 d-flex justify-content-center flex-column">
                  <h6 className="artist-follow-head text-center mt-4">
                    Follow on
                  </h6>
                  <div className="artist-follow-icon mt-1">
                    <Link to={data.data.artists.insta_link} target="_blank">
                      <FaInstagram
                        style={{
                          width: "32px",
                          height: "32px",
                          color: "#024DDF",
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
